import React from 'react'
import classes from './styles.module.css'
import clsx from 'clsx'
import {globalITData} from 'v1/content/home-page'
import Image from 'next/image'

export default function GlobalIT() {
  return (
    <div className={classes.allInOneContainer}>
      <h2 className={clsx('h3-v1 text-center')}>{globalITData?.title}</h2>
      <div className={classes.cardContainer}>
        {globalITData.cards.map((card, idx) => (
          <div key={idx} className={classes.singleCard}>
            <div className={classes.imageContainer}>
              <Image loading="lazy" className={classes.cardImg} src={card.img} alt={card.desc} />
            </div>
            <div className={classes.cardDesc}>{card.desc}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
