import {useState} from 'react'
import Image from 'next/image'
import clsx from 'clsx'
import Button from 'v1/common-components/button/Button'
import IconsMarquee from 'v1/common-components/icons-marquee/IconsMarquee'
import {heroData, heroMarqueeImages} from 'v1/content/home-page'
import Modal from 'v1/common-components/modal/Modal'
import BookDemoForm from 'v1/page-components/marketing/common/demo-form/BookDemoForm'

import ArrowRightIcon from 'v1/assets/icons/arrow-right.svg'
import classes from './styles.module.css'
import repairImg from 'assets/icons/home-page/new-images/it_support_and_repairs.webp'
import manageImg from 'assets/icons/home-page/new-images/manage_your_mdm_access_management.webp'
import procurementImg from 'assets/icons/home-page/new-images/procurement_device_lifecycle_management.webp'
import trackImg from 'assets/icons/home-page/new-images/track_your_assets_apps_3x.webp'

interface DemoData {
  isFreeTrialPage?: boolean
  isNameRequired?: boolean
  heading?: string
  buttonText?: string
  isTermAndCondition?: boolean
  thankYouTitle?: string
  thankYouParagraph?: string
}

const defaultDemoData = {
  isFreeTrialPage: true,
  isNameRequired: true,
  heading: 'Get ZenAdmin for Free',
  buttonText: 'Submit',
  isTermAndCondition: true,
  thankYouTitle: 'Your Account Has Been Created',
  thankYouParagraph:
    'Please check your email for login details to access your ZenAdmin account. Meanwhile, you can schedule a convenient time for a personalized demo.',
}

const newSectionData = [
  {
    img: procurementImg,
    title: 'Procurement & Device lifecycle management ',
    subTitle:
      'Buy all the equipment your employee need — new or exiting. Centralize invoices, tracking logistic and storage worldwide.',
  },
  {
    img: trackImg,
    title: 'Track your assets & apps',
    subTitle:
      'Keep tabs on who has what, for easier offboarding and automate from retrieval to app deprovisioning all in one place.',
  },
  {
    img: manageImg,
    title: 'Manage your MDM, Access management and Security, all in one place.',
    subTitle:
      'Enforce important settings such as updates and encryption, App provisionings, Shadow IT and more.',
  },
  {
    img: repairImg,
    title: 'IT support and repairs',
    subTitle:
      'Provide real-time, 24/7 IT support to your employees & eliminate downtime due to hardware or software issues',
  },
]
export default function Hero() {
  const [isModalOpen, setModalOpen] = useState<boolean>(false)
  const [demoData, setDemoData] = useState<DemoData>({...defaultDemoData})

  const toggleModal = (data: DemoData | null = null) => {
    setDemoData(
      data || {
        ...defaultDemoData,
      },
    )
    setModalOpen(prev => !prev)
  }

  return (
    <>
      <section className={classes.heroSection}>
        <div className={classes.innerHeroSection}>
          <div className={classes.heroTopSection}>
            <div className={classes.topInnerSection}>
              <h1 className="h1-v1">{heroData?.heading}</h1>
              <p className="paragraph1-v1">{heroData?.subHeading}</p>
            </div>
            <div className={classes.ctaContainer}>
              <Button
                text="Get ZenAdmin For Free"
                onClick={() => toggleModal()}
                style={{gap: '12px', borderRadius: '16px', padding: '12px 24px'}}
                iconRight={
                  <span className={classes.btnIconBox}>
                    <Image loading="lazy" src={ArrowRightIcon} alt="login-icon" width={24} height={24} />
                  </span>
                }
                isAnimate={true}
              />
              <Button
                variant="custom"
                text="Book a Demo"
                onClick={() => toggleModal({})}
                style={{gap: '12px', borderRadius: '16px'}}
                className={clsx(classes.bookDemoBtn)}
              />
            </div>
          </div>
          <div className={classes.newSectionBox}>
            {newSectionData.map((data, idx) => (
              <div key={idx}>
                <div className={classes.onlyImgContainer}>
                  <Image loading="lazy" src={data.img} alt={data.title} className={classes.benefitImg} />
                  <div className={classes.overlappingInfoParent}></div>
                  <div className={classes.overlappingInfoContainer}>
                    <div className={classes.overlayTitle}>{data.title}</div>
                    <div className={classes.overlaySubTitle}>{data.subTitle}</div>
                  </div>
                </div>
                <div className={classes.fullDetailsContainer}>
                  <div className={classes.imageTitle}>{data.title}</div>
                  <div className={classes.imageSubTitle}>{data.subTitle}</div>
                  <div className={classes.imgContainer}>
                    <Image loading="lazy" src={data.img} alt={data.title} className={classes.benefitImg} />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div>
          <IconsMarquee
            animate={{x: ['0%', '-50%']}}
            list={[...heroMarqueeImages, ...heroMarqueeImages]}
            iconBoxClass={classes.marqueeLogoBox}
            customMarqueeContainer={classes.heroMarqueeContainer}
          />
        </div>
      </section>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        <BookDemoForm data={demoData} />
      </Modal>
    </>
  )
}
