import React from 'react'
import classes from './styles.module.css'
import clsx from 'clsx'
import {zenAdminOffersData} from 'v1/content/home-page'
import Image from 'next/image'

export default function ZenAdminOffers() {
  return (
    <div className={classes.parentContainer}>
      <div className={classes.allInOneContainer}>
        <h2 className={clsx('h3-v1 text-center')}>{zenAdminOffersData?.title}</h2>
        <div className={classes.otherContainer}>
          <Image
            loading="lazy"
            src={zenAdminOffersData.img}
            alt="zenadmin offers"
            className={classes.zenAdminOffersImage}
          />
        </div>
        <div className={classes.productContainer}>
          <div className={classes.imageContainer2}>
            <Image
              loading="lazy"
              src={zenAdminOffersData.mobImage}
              className={classes.zenAdminOffersMobImage}
              alt="zenadmin-offers"
            />
          </div>
          {/* <div className={classes.productTitle}> Product</div>
          <div className={classes.listContainer}>
            {zenAdminOffersData.product.map((item, idx) => (
              <div key={idx} className={classes.singleItem}>
                <div className={classes.imageParent}>
                  <Image src={item.img} alt={item.desc} className={classes.itemImg} />
                </div>
                <div className={classes.itemDesc}>{item.desc}</div>
              </div>
            ))}
          </div>
          <div className={classes.servicesTitle}> Services </div>
          <div className={classes.listContainer}>
            {zenAdminOffersData.services.map((item, idx) => (
              <div key={idx} className={classes.singleItem}>
                <div className={classes.imageParent}>
                  <Image src={item.img} alt={item.desc} className={classes.itemImg} />
                </div>
                <div className={classes.itemDesc}>{item.desc}</div>
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </div>
  )
}
